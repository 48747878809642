<template>
	<div>
		<b-row>
			<b-col cols="2">
				<b-card>
					<b-card-title class="font-title"
					>
						<feather-icon
								class="mr-1"
								icon="GridIcon"
								size="18"
						/>
						建筑信息
					</b-card-title
					>
					<b-card-text>
						<b-row>
							<b-col class="text-center" cols="4">
								<b-avatar
										class="mb-50"
										size="36"
										variant="light-primary"
								>
									<feather-icon
											icon="HexagonIcon"
											size="18"
									/>
								</b-avatar>
								<b-card-text>
									<h4 class="my-0">
										<span class="font-digit">0</span>
										<small class="text-muted">
											m<sup>2</sup></small
										>
									</h4>
									<label>建筑面积</label>
								</b-card-text>
							</b-col>

							<b-col class="text-center" cols="4">
								<b-avatar
										class="mb-50"
										size="36"
										variant="light-success"
								>
									<feather-icon icon="LayersIcon" size="18"/>
								</b-avatar>
								<b-card-text>
									<h4 class="my-0">
										<span class="font-digit">0</span>
										<small class="text-muted"> 层</small>
									</h4>
									<label>建筑楼层</label>
								</b-card-text>
							</b-col>

							<b-col class="text-center" cols="4">
								<b-avatar
										class="mb-50"
										size="36"
										variant="light-warning"
								>
									<feather-icon icon="ClockIcon" size="18"/>
								</b-avatar>
								<b-card-text>
									<h4 class="my-0">
										<span class="font-digit"> 0 </span>
										<small class="text-muted"> 年</small>
									</h4>
									<label>建成年份</label>
								</b-card-text>
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>

				<b-card style="height: 772px">
					<b-card-title class="font-title"
					>
						<feather-icon
								class="mr-1"
								icon="GridIcon"
								size="18"
						/>
						C座建筑综合体
					</b-card-title
					>
					<b-card-text>
						<b-row>
							<b-col cols="6">
								<span class="mr-50">能耗</span>
								<span
										class="text-primary font-digit"
										style="font-size: 1.5rem"
								>0</span
								>
								<small class="text-muted ml-50">kWh</small>
							</b-col>
							<b-col cols="6">
								<span class="mr-50">碳排</span>
								<span
										class="text-success font-digit"
										style="font-size: 1.5rem"
								>0</span
								>
								<small class="text-muted ml-50">kgCO2</small>
							</b-col>
							<b-col cols="12">
								<div class="d-flex align-items-center mt-1">
									<b-avatar
											class="mr-2"
											size="48"
											variant="light-success"
									>
										<h3
												class="font-digit text-success my-0"
										>
											CO<sub>2</sub>
										</h3>
									</b-avatar>
									<b-card-text>
										<h3 class="my-0">
											<span class="font-digit">0</span>
											<span
													class="text-muted font-small-2"
											>&nbsp;kgCO<sub>2</sub></span
											>
										</h3>
										<b-card-text class="my-0">
											年度碳排放
										</b-card-text
										>
										<b-card-text
												class="
                                                text-muted
                                                mb-0
                                                d-flex
                                                align-items-center
                                                font-small-3
                                            "
												style="margin-top: 0.5rem"
										>
											<span>去年 0 </span>
											<span
											>&nbsp;kgCO<sub>2</sub>&nbsp;</span
											>
											<div
													class="
                                                    d-flex
                                                    align-items-center
                                                    text-success
                                                    ml-25
                                                "
											>
												<span>0%</span>
												<feather-icon
														icon="TrendingDownIcon"
												/>
											</div>
										</b-card-text>
									</b-card-text>
								</div>
							</b-col>
						</b-row>
					</b-card-text>

					<hr class="my-2"/>

					<p class="font-title">区域能耗</p>
					<div id="chart_1" class="w-100" style="height: 11rem"></div>

					<hr class="my-2"/>

					<p class="font-title">环境监测</p>
					<b-card-text>
						<b-row>
							<b-col cols="6">
								<div id="chart_2" style="height: 7rem"></div>
							</b-col>
							<b-col class="d-flex align-items-center" cols="6">
								<div style="width: 100%">
									<h4
											class="
                                            d-flex
                                            justify-content-between
                                            align-items-end
                                        "
									>
										<label>温度</label>
										<div>
											<span class="font-digit">0</span>
											<span
													class="text-muted font-small-2"
											>&nbsp;℃</span
											>
										</div>
									</h4>
									<h4
											class="
                                            my-0
                                            d-flex
                                            justify-content-between
                                            align-items-end
                                        "
									>
										<label>湿度</label>
										<div>
											<span class="font-digit">0</span>
											<span
													class="text-muted font-small-2"
											>&nbsp;%</span
											>
										</div>
									</h4>
									<h4
											class="
                                            my-0
                                            d-flex
                                            justify-content-between
                                            align-items-end
                                        "
									>
										<label>VOC</label>
										<div>
											<span class="font-digit">0</span>
											<span
													class="text-muted font-small-2"
											>&nbsp;ppb</span
											>
										</div>
									</h4>
								</div>
							</b-col>
							<b-col cols="12">
								<div
										id="chart_7"
										style="height: 11.5rem"
										class="mt-2"
								></div>
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>
			</b-col>

			<b-col cols="8">
				<b-row>
					<b-col cols="3">
						<b-card style="height: 586px">
							<b-card-title class="font-title"
							>
								<feather-icon
										class="mr-1"
										icon="GridIcon"
										size="18"
								/>
								A座住宅
							</b-card-title
							>
							<b-card-text>
								<b-row>
									<b-col cols="6">
										<span class="mr-50">能耗</span>
										<span
												class="text-primary font-digit"
												style="font-size: 1.5rem"
										>0</span
										>
										<small class="text-muted ml-50"
										>kWh</small
										>
									</b-col>
									<b-col cols="6">
										<span class="mr-50">碳排</span>
										<span
												class="text-success font-digit"
												style="font-size: 1.5rem"
										>0</span
										>
										<small class="text-muted ml-50"
										>kgCO2</small
										>
									</b-col>
									<b-col cols="12">
										<div class="d-flex align-items-center mt-1 ">
											<b-avatar
													class="mr-2"
													size="48"
													variant="light-success"
											>
												<h3 class=" font-digit text-success my-0 ">
													CO<sub>2</sub>
												</h3>
											</b-avatar>
											<b-card-text>
												<h3 class="my-0"><span class="font-digit">0</span>
													<span class="text-muted font-small-2">&nbsp;kgCO<sub>2</sub></span>
												</h3>
												<b-card-text class="my-0">
													年度碳排放
												</b-card-text
												>
												<b-card-text
														class="
                                                        text-muted
                                                        mb-0
                                                        d-flex
                                                        align-items-center
                                                        font-small-3
                                                    "
														style="margin-top: 0.5rem"
												>
													<span>去年 0 </span>
													<span
													>&nbsp;kgCO<sub>2</sub>&nbsp;</span
													>
													<div
															class="
                                                            d-flex
                                                            align-items-center
                                                            text-success
                                                            ml-25
                                                        "
													>
														<span>0%</span>
														<feather-icon
																icon="TrendingDownIcon"
														/>
													</div>
												</b-card-text>
											</b-card-text>
										</div>

										<hr class="my-2"/>

										<p class="font-title">逐月能耗</p>

										<div
												id="chart_3"
												style="height: 10rem"
										></div>

										<hr class="my-2"/>

										<p class="font-title">室内环境</p>
										<b-row>
											<b-col cols="6">
												<div
														id="chart_4"
														style="height: 7rem"
												></div>
											</b-col>
											<b-col
													class="d-flex align-items-center"
													cols="6"
											>
												<div style="width: 100%">
													<h4
															class="d-flex justify-content-between align-items-end"
													>
														<label>温度</label>
														<div>
                                                            <span
		                                                            class="
                                                                    font-digit
                                                                "
                                                            >0</span
                                                            >
															<span
																	class="
                                                                    text-muted
                                                                    font-small-2
                                                                "
															>&nbsp;℃</span
															>
														</div>
													</h4>
													<h4
															class="
                                                            my-0
                                                            d-flex
                                                            justify-content-between
                                                            align-items-end
                                                        "
													>
														<label>湿度</label>
														<div>
                                                            <span
		                                                            class="
                                                                    font-digit
                                                                "
                                                            >0</span
                                                            >
															<span
																	class="
                                                                    text-muted
                                                                    font-small-2
                                                                "
															>&nbsp;%</span
															>
														</div>
													</h4>
													<h4
															class="
                                                            my-0
                                                            d-flex
                                                            justify-content-between
                                                            align-items-end
                                                        "
													>
														<label>VOC</label>
														<div>
                                                            <span
		                                                            class="
                                                                    font-digit
                                                                "
                                                            >0</span
                                                            >
															<span
																	class="
                                                                    text-muted
                                                                    font-small-2
                                                                "
															>&nbsp;ppb</span
															>
														</div>
													</h4>
												</div>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
							</b-card-text>
						</b-card>
					</b-col>

					<b-col cols="6">
						<div
								class="font-title text-center mb-1"
								style="letter-spacing: 0.5rem; font-size: 2rem"
						>
							湾厦中心综合信息展示
						</div>
						<b-card-text
								class="rounded shadow overflow-hidden"
								style="margin-bottom: 2rem"
						>
							<b-carousel
									id="carousel-fade"
									controls
									fade
									indicators
							>
								<b-carousel-slide
										style="
                                        background: url(/images/hjj.png) center
                                            center / cover;
                                        width: 100%;
                                        height: 417px;
                                    "
								>
								</b-carousel-slide>
							</b-carousel>
						</b-card-text>

						<b-card>
							<b-row>
								<b-col cols="4">
									<div class="d-flex align-items-center">
										<b-avatar
												class="mr-1"
												rounded
												size="42"
												variant="light-primary"
										>
											<feather-icon
													icon="StarIcon"
													size="24"
											/>
										</b-avatar>
										<b-card-text>
											<h4 class="my-0">
                                                <span class="font-digit"
                                                >0</span
                                                >
												<span
														class="
                                                        text-muted
                                                        font-small-2
                                                    "
												>&nbsp;kWh</span
												>
											</h4>
											<label class="my-0">总能耗</label>
										</b-card-text>
									</div>
								</b-col>

								<b-col cols="4">
									<div class="d-flex align-items-center">
										<b-avatar
												class="mr-1"
												rounded
												size="42"
												variant="light-success"
										>
											<feather-icon
													icon="StarIcon"
													size="24"
											/>
										</b-avatar>
										<b-card-text>
											<h4 class="my-0">
                                                <span class="font-digit"
                                                >0</span
                                                >
												<span
														class="
                                                        text-muted
                                                        font-small-2
                                                    "
												>&nbsp;kgCO<sub
												>2</sub
												></span
												>
											</h4>
											<label class="my-0"
											>总碳排放量</label
											>
										</b-card-text>
									</div>
								</b-col>

								<b-col cols="4">
									<div class="d-flex align-items-center">
										<b-avatar
												class="mr-1"
												rounded
												size="42"
												variant="light-warning"
										>
											<feather-icon
													icon="StarIcon"
													size="24"
											/>
										</b-avatar>
										<b-card-text>
											<h4 class="my-0">
                                                <span class="font-digit"
                                                >0</span
                                                >
												<span
														class="
                                                        text-muted
                                                        font-small-2
                                                    "
												>&nbsp;kgCO<sub>2</sub>/m<sup
												>2</sup
												></span
												>
											</h4>
											<label class="my-0"
											>碳排放强度</label
											>
										</b-card-text>
									</div>
								</b-col>
							</b-row>
						</b-card>
					</b-col>

					<b-col cols="3" style="height: 586px">
						<b-card style="height: 586px">
							<b-card-title class="font-title"
							>
								<feather-icon
										class="mr-1"
										icon="GridIcon"
										size="18"
								/>
								B座住宅
							</b-card-title
							>
							<b-card-text>
								<b-row>
									<b-col cols="6">
										<span class="mr-50">能耗</span>
										<span
												class="text-primary font-digit"
												style="font-size: 1.5rem"
										>0</span
										>
										<small class="text-muted ml-50"
										>kWh</small
										>
									</b-col>
									<b-col cols="6">
										<span class="mr-50">碳排</span>
										<span
												class="text-success font-digit"
												style="font-size: 1.5rem"
										>0</span
										>
										<small class="text-muted ml-50"
										>kgCO2</small
										>
									</b-col>
									<b-col cols="12">
										<div
												class="
                                                d-flex
                                                align-items-center
                                                mt-1
                                            "
										>
											<b-avatar
													class="mr-2"
													size="48"
													variant="light-success"
											>
												<h3
														class="
                                                        font-digit
                                                        text-success
                                                        my-0
                                                    "
												>
													CO<sub>2</sub>
												</h3>
											</b-avatar>
											<b-card-text>
												<h3 class="my-0">
                                                    <span class="font-digit"
                                                    >0</span
                                                    >
													<span
															class="
                                                            text-muted
                                                            font-small-2
                                                        "
													>&nbsp;kgCO<sub
													>2</sub
													></span
													>
												</h3>
												<b-card-text class="my-0">
													年度碳排放
												</b-card-text
												>
												<b-card-text
														class="
                                                        text-muted
                                                        mb-0
                                                        d-flex
                                                        align-items-center
                                                        font-small-3
                                                    "
														style="margin-top: 0.5rem"
												>
													<span>去年 0 </span>
													<span
													>&nbsp;kgCO<sub>2</sub>&nbsp;</span
													>
													<div
															class="
                                                            d-flex
                                                            align-items-center
                                                            text-success
                                                            ml-25
                                                        "
													>
														<span>0%</span>
														<feather-icon
																icon="TrendingDownIcon"
														/>
													</div>
												</b-card-text>
											</b-card-text>
										</div>

										<hr class="my-2"/>

										<p class="font-title">逐月能耗</p>

										<div
												id="chart_5"
												style="height: 10rem"
										></div>

										<hr class="my-2"/>

										<p class="font-title">室内环境</p>
										<b-row>
											<b-col cols="6">
												<div
														id="chart_6"
														style="height: 7rem"
												></div>
											</b-col>
											<b-col
													class="
                                                    d-flex
                                                    align-items-center
                                                "
													cols="6"
											>
												<div style="width: 100%">
													<h4
															class="
                                                            d-flex
                                                            justify-content-between
                                                            align-items-end
                                                        "
													>
														<label>温度</label>
														<div>
                                                            <span
		                                                            class="
                                                                    font-digit
                                                                "
                                                            >0</span
                                                            >
															<span
																	class="
                                                                    text-muted
                                                                    font-small-2
                                                                "
															>&nbsp;℃</span
															>
														</div>
													</h4>
													<h4
															class="
                                                            my-0
                                                            d-flex
                                                            justify-content-between
                                                            align-items-end
                                                        "
													>
														<label>湿度</label>
														<div>
                                                            <span
		                                                            class="
                                                                    font-digit
                                                                "
                                                            >0</span
                                                            >
															<span
																	class="
                                                                    text-muted
                                                                    font-small-2
                                                                "
															>&nbsp;%</span
															>
														</div>
													</h4>
													<h4
															class="
                                                            my-0
                                                            d-flex
                                                            justify-content-between
                                                            align-items-end
                                                        "
													>
														<label>VOC</label>
														<div>
                                                            <span
		                                                            class="
                                                                    font-digit
                                                                "
                                                            >0</span
                                                            >
															<span
																	class="
                                                                    text-muted
                                                                    font-small-2
                                                                "
															>&nbsp;ppb</span
															>
														</div>
													</h4>
												</div>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
							</b-card-text>
						</b-card>
					</b-col>
				</b-row>

				<b-row>
					<b-col cols="3">
						<b-card style="height: 364px">
							<b-card-title class="font-title"
							>
								<feather-icon
										class="mr-1"
										icon="GridIcon"
										size="18"
								/>
								碳汇
							</b-card-title
							>
							<b-card-text>
								<statistic-card-with-area-chart
										:chart-data="subscribersGained.series"
										:statistic="
                                        kFormatter(
                                            subscribersGained.analyticsData
                                                .subscribers
                                        )
                                    "
										color="primary"
										icon="ZapIcon"
										statistic-title="碳汇 (kgCO2)"
										style="height: 100px"
								/>

								<div
										id="chart_8"
										class="w-100 mt-2"
										style="height: 90px"
								></div>

								<b-row class="mt-2">
									<b-col class="text-center" cols="4">
										<b-card-text>
											<h4 class="my-0">
                                                <span class="font-digit"
                                                >0</span
                                                >
												<small class="text-muted">
													kgCO<sub>2</sub></small
												>
											</h4>
											<label>可再生能源</label>
										</b-card-text>
									</b-col>

									<b-col class="text-center" cols="4">
										<b-card-text>
											<h4 class="my-0">
                                                <span class="font-digit"
                                                >0</span
                                                >
												<small class="text-muted">
													kgCO<sub>2</sub></small
												>
											</h4>
											<label>植树造林</label>
										</b-card-text>
									</b-col>

									<b-col class="text-center" cols="4">
										<b-card-text>
											<h4 class="my-0">
                                                <span class="font-digit">
                                                    0
                                                </span>
												<small class="text-muted">
													kgCO<sub>2</sub></small
												>
											</h4>
											<label>碳交易</label>
										</b-card-text>
									</b-col>
								</b-row>
							</b-card-text>
						</b-card>
					</b-col>

					<b-col cols="3">
						<b-card style="height: 364px">
							<b-card-title class="font-title"
							>
								<feather-icon
										class="mr-1"
										icon="GridIcon"
										size="18"
								/>
								建筑运行能耗
							</b-card-title
							>
							<b-card-text>
								<div class="d-flex align-items-center mb-2">
									<div style="width: 4rem" class="font-title">
										A座
									</div>
									<div class="w-100">
										<div>
                                            <span
                                            ><span
		                                            class="font-digit"
		                                            style="font-size: 1.5rem"
                                            >30</span
                                            >
                                                <small class="text-muted"
                                                >&nbsp;kWh</small
                                                ></span
                                            >
											<span class="float-right"
											><span>100</span>
                                                <small class="text-muted"
                                                >&nbsp;kWh</small
                                                ></span
											>
										</div>
										<b-progress max="100" height="1rem">
											<b-progress-bar
													value="30"
													label="30%"
													striped
													animated
											></b-progress-bar>
										</b-progress>
									</div>
								</div>

								<div class="d-flex align-items-center mb-2">
									<div style="width: 4rem" class="font-title">
										B座
									</div>
									<div class="w-100">
										<div>
                                            <span
                                            ><span
		                                            class="font-digit"
		                                            style="font-size: 1.5rem"
                                            >60</span
                                            >
                                                <small class="text-muted"
                                                >&nbsp;kWh</small
                                                ></span
                                            >
											<span class="float-right"
											><span>100</span>
                                                <small class="text-muted"
                                                >&nbsp;kWh</small
                                                ></span
											>
										</div>
										<b-progress max="100" height="1rem">
											<b-progress-bar
													value="60"
													label="60%"
													striped
													animated
											></b-progress-bar>
										</b-progress>
									</div>
								</div>

								<div class="d-flex align-items-center mb-2">
									<div style="width: 4rem" class="font-title">
										C座
									</div>
									<div class="w-100">
										<div>
                                            <span
                                            ><span
		                                            class="font-digit"
		                                            style="font-size: 1.5rem"
                                            >50</span
                                            >
                                                <small class="text-muted"
                                                >&nbsp;kWh</small
                                                ></span
                                            >
											<span class="float-right"
											><span>100</span>
                                                <small class="text-muted"
                                                >&nbsp;kWh</small
                                                ></span
											>
										</div>
										<b-progress max="100" height="1rem">
											<b-progress-bar
													value="50"
													label="50%"
													striped
													animated
											></b-progress-bar>
										</b-progress>
									</div>
								</div>

								<div class="d-flex align-items-center mb-2">
									<div style="width: 4rem" class="font-title">
										D座
									</div>
									<div class="w-100">
										<div>
                                            <span
                                            ><span
		                                            class="font-digit"
		                                            style="font-size: 1.5rem"
                                            >80</span
                                            >
                                                <small class="text-muted"
                                                >&nbsp;kWh</small
                                                ></span
                                            >
											<span class="float-right"
											><span>100</span>
                                                <small class="text-muted"
                                                >&nbsp;kWh</small
                                                ></span
											>
										</div>
										<b-progress max="100" height="1rem">
											<b-progress-bar
													value="80"
													label="80%"
													striped
													animated
											></b-progress-bar>
										</b-progress>
									</div>
								</div>

								<div class="d-flex align-items-center mb-2">
									<div style="width: 4rem" class="font-title">
										其它
									</div>
									<div class="w-100">
										<div>
                                            <span
                                            ><span
		                                            class="font-digit"
		                                            style="font-size: 1.5rem"
                                            >40</span
                                            >
                                                <small class="text-muted"
                                                >&nbsp;kWh</small
                                                ></span
                                            >
											<span class="float-right"
											><span>100</span>
                                                <small class="text-muted"
                                                >&nbsp;kWh</small
                                                ></span
											>
										</div>
										<b-progress max="100" height="1rem">
											<b-progress-bar
													value="40"
													label="40%"
													striped
													animated
											></b-progress-bar>
										</b-progress>
									</div>
								</div>
							</b-card-text>
						</b-card>
					</b-col>

					<b-col cols="3">
						<b-card style="height: 364px">
							<b-card-title class="font-title"
							>
								<feather-icon
										class="mr-1"
										icon="GridIcon"
										size="18"
								/>
								建筑运行碳排放
							</b-card-title
							>
							<b-card-text>
								<div class="d-flex align-items-center mb-2">
									<div style="width: 4rem" class="font-title">
										A座
									</div>
									<div class="w-100">
										<div>
                                            <span
                                            ><span
		                                            class="font-digit"
		                                            style="font-size: 1.5rem"
                                            >60</span
                                            >
                                                <small class="text-muted"
                                                >&nbsp;kgCO2</small
                                                ></span
                                            >
											<span class="float-right"
											><span>100</span>
                                                <small class="text-muted"
                                                >&nbsp;kgCO2</small
                                                ></span
											>
										</div>
										<b-progress max="100" height="1rem">
											<b-progress-bar
													value="60"
													label="60%"
													striped
													animated
													variant="success"
											></b-progress-bar>
										</b-progress>
									</div>
								</div>

								<div class="d-flex align-items-center mb-2">
									<div style="width: 4rem" class="font-title">
										B座
									</div>
									<div class="w-100">
										<div>
                                            <span
                                            ><span
		                                            class="font-digit"
		                                            style="font-size: 1.5rem"
                                            >40</span
                                            >
                                                <small class="text-muted"
                                                >&nbsp;kgCO2</small
                                                ></span
                                            >
											<span class="float-right"
											><span>100</span>
                                                <small class="text-muted"
                                                >&nbsp;kgCO2</small
                                                ></span
											>
										</div>
										<b-progress max="100" height="1rem">
											<b-progress-bar
													value="40"
													label="40%"
													striped
													animated
													variant="success"
											></b-progress-bar>
										</b-progress>
									</div>
								</div>

								<div class="d-flex align-items-center mb-2">
									<div style="width: 4rem" class="font-title">
										C座
									</div>
									<div class="w-100">
										<div>
                                            <span
                                            ><span
		                                            class="font-digit"
		                                            style="font-size: 1.5rem"
                                            >70</span
                                            >
                                                <small class="text-muted"
                                                >&nbsp;kgCO2</small
                                                ></span
                                            >
											<span class="float-right"
											><span>100</span>
                                                <small class="text-muted"
                                                >&nbsp;kgCO2</small
                                                ></span
											>
										</div>
										<b-progress max="100" height="1rem">
											<b-progress-bar
													value="70"
													label="70%"
													striped
													animated
													variant="success"
											></b-progress-bar>
										</b-progress>
									</div>
								</div>

								<div class="d-flex align-items-center mb-2">
									<div style="width: 4rem" class="font-title">
										D座
									</div>
									<div class="w-100">
										<div>
                                            <span
                                            ><span
		                                            class="font-digit"
		                                            style="font-size: 1.5rem"
                                            >30</span
                                            >
                                                <small class="text-muted"
                                                >&nbsp;kgCO2</small
                                                ></span
                                            >
											<span class="float-right"
											><span>100</span>
                                                <small class="text-muted"
                                                >&nbsp;kgCO2</small
                                                ></span
											>
										</div>
										<b-progress max="100" height="1rem">
											<b-progress-bar
													value="30"
													label="30%"
													striped
													animated
													variant="success"
											></b-progress-bar>
										</b-progress>
									</div>
								</div>

								<div class="d-flex align-items-center mb-2">
									<div style="width: 4rem" class="font-title">
										其它
									</div>
									<div class="w-100">
										<div>
                                            <span
                                            ><span
		                                            class="font-digit"
		                                            style="font-size: 1.5rem"
                                            >90</span
                                            >
                                                <small class="text-muted"
                                                >&nbsp;kgCO2</small
                                                ></span
                                            >
											<span class="float-right"
											><span>100</span>
                                                <small class="text-muted"
                                                >&nbsp;kgCO2</small
                                                ></span
											>
										</div>
										<b-progress max="100" height="1rem">
											<b-progress-bar
													value="90"
													label="90%"
													striped
													animated
													variant="success"
											></b-progress-bar>
										</b-progress>
									</div>
								</div>
							</b-card-text>
						</b-card>
					</b-col>

					<b-col cols="3">
						<b-card style="height: 364px">
							<b-card-title class="font-title"
							>
								<feather-icon
										class="mr-1"
										icon="GridIcon"
										size="18"
								/>
								其它
							</b-card-title
							>
							<b-card-text>
								<b-row>
									<b-col cols="6">
										<span class="mr-50">能耗</span>
										<span
												class="text-primary font-digit"
												style="font-size: 1.5rem"
										>0</span
										>
										<small class="text-muted ml-50"
										>kWh</small
										>
									</b-col>
									<b-col cols="6">
										<span class="mr-50">碳排</span>
										<span
												class="text-success font-digit"
												style="font-size: 1.5rem"
										>0</span
										>
										<small class="text-muted ml-50"
										>kgCO2</small
										>
									</b-col>
									<b-col cols="12">
										<statistic-card-with-area-chart
												v-if="
                                                subscribersGained.analyticsData
                                            "
												:chart-data="
                                                subscribersGained.series
                                            "
												:statistic="
                                                kFormatter(
                                                    subscribersGained
                                                        .analyticsData
                                                        .subscribers
                                                )
                                            "
												color="info"
												icon="DropletIcon"
												statistic-title="湿度 (%)"
												style="height: 120px"
												class="mt-1"
										/>

										<div
												id="chart_9"
												class="w-100 mt-2"
												style="height: 100px"
										></div>
									</b-col>
								</b-row>
							</b-card-text>
						</b-card>
					</b-col>
				</b-row>
			</b-col>

			<b-col cols="2">
				<b-card>
					<b-card-title class="font-title"
					>
						<feather-icon
								class="mr-1"
								icon="GridIcon"
								size="18"
						/>
						室外环境
					</b-card-title
					>
					<b-card-text>
						<b-row>
							<b-col class="pr-0" cols="5">
								<div class="d-flex align-items-center mb-1">
									<b-avatar
											rounded
											size="36"
											style="margin-right: 0.5rem"
											variant="light-success"
									>
										<feather-icon
												icon="ThermometerIcon"
												size="18"
										/>
									</b-avatar>
									<b-card-text>
										<h4 class="my-0">
											<span class="font-digit">0 </span>
											<span
													class="text-muted font-small-2"
											>℃</span
											>
										</h4>
										<label class="my-0"> 温度</label>
									</b-card-text>
								</div>
							</b-col>

							<b-col class="pr-0" cols="7">
								<div class="d-flex align-items-center mb-1">
									<b-avatar
											rounded
											size="36"
											style="margin-right: 0.5rem"
											variant="light-primary"
									>
										<feather-icon
												icon="WindIcon"
												size="18"
										/>
									</b-avatar>
									<b-card-text>
										<h4 class="my-0">
											<span class="font-digit">0 </span>
											<span
													class="text-muted font-small-2"
											>
                                                hPa</span
											>
										</h4>
										<label class="my-0">大气压 </label>
									</b-card-text>
								</div>
							</b-col>

							<b-col class="pr-0" cols="5">
								<div class="d-flex align-items-center">
									<b-avatar
											rounded
											size="36"
											style="margin-right: 0.5rem"
											variant="light-warning"
									>
										<feather-icon
												icon="DropletIcon"
												size="18"
										/>
									</b-avatar>
									<b-card-text>
										<h4 class="my-0">
											<span class="font-digit">0 </span>
											<span
													class="text-muted font-small-2"
											>
                                                %</span
											>
										</h4>
										<label class="my-0"> 湿度</label>
									</b-card-text>
								</div>
							</b-col>

							<b-col class="pr-0" cols="7">
								<div class="d-flex align-items-center">
									<b-avatar
											rounded
											size="36"
											style="margin-right: 0.5rem"
											variant="light-info"
									>
										<feather-icon
												icon="SunIcon"
												size="18"
										/>
									</b-avatar>
									<b-card-text>
										<h4 class="my-0">
											<span class="font-digit">0</span>
											<span
													class="text-muted font-small-2"
											>
                                                W/m<sup>2</sup></span
											>
										</h4>
										<label class="my-0"> 辐照</label>
									</b-card-text>
								</div>
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>

				<b-card style="height: 762px">
					<b-card-title class="font-title"
					>
						<feather-icon
								class="mr-1"
								icon="GridIcon"
								size="18"
						/>
						D座住宅
					</b-card-title
					>
					<b-card-text>
						<b-row>
							<b-col cols="6">
								<span class="mr-50">能耗</span>
								<span
										class="text-primary font-digit"
										style="font-size: 1.5rem"
								>0</span
								>
								<small class="text-muted ml-50">kWh</small>
							</b-col>
							<b-col cols="6">
								<span class="mr-50">碳排</span>
								<span
										class="text-success font-digit"
										style="font-size: 1.5rem"
								>0</span
								>
								<small class="text-muted ml-50">kgCO2</small>
							</b-col>
							<b-col cols="12">
								<div class="d-flex align-items-center mt-1">
									<b-avatar
											class="mr-2"
											size="48"
											variant="light-success"
									>
										<h3
												class="font-digit text-success my-0"
										>
											CO<sub>2</sub>
										</h3>
									</b-avatar>
									<b-card-text>
										<h3 class="my-0">
											<span class="font-digit">0</span>
											<span
													class="text-muted font-small-2"
											>&nbsp;kgCO<sub>2</sub></span
											>
										</h3>
										<b-card-text class="my-0">
											年度碳排放
										</b-card-text
										>
										<b-card-text
												class="
                                                text-muted
                                                mb-0
                                                d-flex
                                                align-items-center
                                                font-small-3
                                            "
												style="margin-top: 0.5rem"
										>
											<span>去年 0 </span>
											<span
											>&nbsp;kgCO<sub>2</sub>&nbsp;</span
											>
											<div
													class="
                                                    d-flex
                                                    align-items-center
                                                    text-success
                                                    ml-25
                                                "
											>
												<span>0%</span>
												<feather-icon
														icon="TrendingDownIcon"
												/>
											</div>
										</b-card-text>
									</b-card-text>
								</div>
							</b-col>
						</b-row>
					</b-card-text>

					<hr class="my-2"/>

					<p class="font-title">区域能耗</p>
					<div
							id="chart_11"
							class="w-100"
							style="height: 11rem"
					></div>

					<hr class="my-2"/>

					<p class="font-title">环境监测</p>
					<b-card-text>
						<b-row>
							<b-col cols="6">
								<div id="chart_12" style="height: 7rem"></div>
							</b-col>
							<b-col class="d-flex align-items-center" cols="6">
								<div style="width: 100%">
									<h4
											class="
                                            d-flex
                                            justify-content-between
                                            align-items-end
                                        "
									>
										<label>温度</label>
										<div>
											<span class="font-digit">0</span>
											<span
													class="text-muted font-small-2"
											>&nbsp;℃</span
											>
										</div>
									</h4>
									<h4
											class="
                                            my-0
                                            d-flex
                                            justify-content-between
                                            align-items-end
                                        "
									>
										<label>湿度</label>
										<div>
											<span class="font-digit">0</span>
											<span
													class="text-muted font-small-2"
											>&nbsp;%</span
											>
										</div>
									</h4>
									<h4
											class="
                                            my-0
                                            d-flex
                                            justify-content-between
                                            align-items-end
                                        "
									>
										<label>VOC</label>
										<div>
											<span class="font-digit">0</span>
											<span
													class="text-muted font-small-2"
											>&nbsp;ppb</span
											>
										</div>
									</h4>
								</div>
							</b-col>
							<b-col cols="12">
								<div
										id="chart_10"
										style="height: 11rem"
										class="mt-2"
								></div>
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import {Bar, Column, Line, Liquid, Pie,} from "@antv/g2plot";
import StatisticCardWithAreaChart from "@/@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import {$themeColors} from "@themeConfig";
import {kFormatter} from "@/@core/utils/filter";
import VueApexCharts from "vue-apexcharts";

const $earningsStrokeColor2 = "#28c76f66";
const $earningsStrokeColor3 = "#28c76f33";

export default {
	name: "Hjj2Demo1",
	components: {
		StatisticCardWithAreaChart,
		VueApexCharts,
	},
	data() {
		return {
			selected: "",
			subscribersGained: {
				series: [
					{
						name: "Subscribers",
						data: [28, 40, 36, 32, 38, 44, 35],
					},
				],
				analyticsData: {
					subscribers: 26,
				},
			},

			earningsChart: {
				series: [53, 16, 31],
				chartOptions: {
					chart: {
						type: "donut",
						toolbar: {
							show: false,
						},
					},
					dataLabels: {
						enabled: false,
					},
					legend: {show: false},
					comparedResult: [2, -3, 8],
					labels: ["空调用电", "照明插座用电", "动力用电"],
					stroke: {width: 0},
					colors: [
						$earningsStrokeColor2,
						$earningsStrokeColor3,
						$themeColors.success,
					],
					grid: {
						padding: {
							right: -20,
							bottom: -8,
							left: -20,
						},
					},
					plotOptions: {
						pie: {
							startAngle: -10,
							donut: {
								labels: {
									show: true,
									name: {
										offsetY: 15,
									},
									value: {
										offsetY: -15,
										formatter(val) {
											// eslint-disable-next-line radix
											return `${parseInt(val)}%`;
										},
									},
									total: {
										show: true,
										offsetY: 15,
										label: "空调用电",
										formatter() {
											return "53%";
										},
									},
								},
							},
						},
					},
					responsive: [
						{
							breakpoint: 1325,
							options: {
								chart: {
									height: 100,
								},
							},
						},
						{
							breakpoint: 1200,
							options: {
								chart: {
									height: 120,
								},
							},
						},
						{
							breakpoint: 1045,
							options: {
								chart: {
									height: 100,
								},
							},
						},
						{
							breakpoint: 992,
							options: {
								chart: {
									height: 120,
								},
							},
						},
					],
				},
			},

			revenue_report: {},
			revenueReport: {
				series: [
					{
						name: "Earning",
						data: [
							95, 177, 284, 256, 105, 63, 168, 218, 72, 87, 125,
							23,
						],
					},
				],
				chartOptions: {
					chart: {
						stacked: true,
						type: "bar",
						toolbar: {show: false},
					},
					grid: {
						yaxis: {
							lines: {show: false},
						},
					},
					xaxis: {
						categories: [
							"一月",
							"二月",
							"三月",
							"四月",
							"五月",
							"六月",
							"七月",
							"八月",
							"九月",
							"十月",
							"十一月",
							"十二月",
						],
						labels: {
							style: {
								colors: "#b9b9c3",
								fontSize: "0.86rem",
							},
						},
						axisTicks: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
					},
					legend: {
						show: true,
					},
					dataLabels: {
						enabled: false,
					},
					colors: [$themeColors.warning, $themeColors.primary],
					plotOptions: {
						bar: {
							columnWidth: "50%",
							endingShape: "rounded",
						},
						distributed: true,
					},
					yaxis: {
						labels: {
							style: {
								colors: "#b9b9c3",
								fontSize: "0.86rem",
							},
						},
					},
				},
			},
		};
	},
	methods: {
		kFormatter,
		chart_init_1() {
			const data = [
				{type: "C座商业", value: 37},
				{type: "C座会所", value: 52},
				{type: "C座其它", value: 18},
			];

			const piePlot = new Pie("chart_1", {
				appendPadding: 10,
				data,
				angleField: "value",
				colorField: "type",
				radius: 1,
				innerRadius: 0.6,
				pieStyle: {
					lineWidth: 0,
				},
				label: {
					type: "inner",
					offset: "-50%",
					content: "{value}",
					style: {
						textAlign: "center",
						fontSize: 14,
					},
				},
				interactions: [
					{type: "element-selected"},
					{type: "element-active"},
				],
				statistic: {
					title: {
						formatter: () => "合计",
						offsetY: -8,
						style: {
							fontSize: "0.9rem",
							color: "",
						},
					},
					content: {
						offsetY: -4,
						style: {
							fontSize: "1.5rem",
							fontWeight: "normal",
							color: "",
						},
					},
				},
				legend: {
					itemName: {
						style: {
							fill: "#808695",
						},
					},
				},
			});

			piePlot.render();
		},
		chart_init_2() {
			const liquidPlot = new Liquid("chart_2", {
				percent: 0.5,
				outline: {
					border: 2,
					distance: 0,
				},
				statistic: {
					title: {
						formatter: () => "CO2",
						style: ({percent}) => ({
							fontSize: "1rem",
							fill: percent >= 0.65 ? "white" : "",
						}),
					},
					content: {
						style: ({percent}) => ({
							fontWeight: 100,
							fontSize: "1.5rem",
							fill: percent >= 0.5 ? "white" : "",
						}),
						customHtml: (container, view, {percent}) => {
							const text = `${(percent * 1000).toFixed(0)}`;
							return `<div>${text}</div>`;
						},
					},
				},
				wave: {
					length: 125,
				},
			});
			liquidPlot.render();
		},
		chart_init_3() {
			const data = [
				{
					type: "2022-01",
					sales: 38,
				},
				{
					type: "2022-02",
					sales: 52,
				},
				{
					type: "2022-03",
					sales: 61,
				},
				{
					type: "2022-04",
					sales: 145,
				},
				{
					type: "2022-05",
					sales: 48,
				},
				{
					type: "2022-06",
					sales: 38,
				},
				{
					type: "2022-07",
					sales: 38,
				},
				{
					type: "2022-08",
					sales: 38,
				},
			];

			const columnPlot = new Column("chart_3", {
				data,
				xField: "type",
				yField: "sales",
				color: "#7367f0",
				label: {
					position: "top", // 'top', 'bottom', 'middle',
					// 配置样式
					style: {
						fill: "#FFFFFF",
						opacity: 0.6,
					},
				},
				xAxis: {
					label: {
						autoHide: true,
						autoRotate: false,
					},
				},
				yAxis: {
					grid: null,
				},
				meta: {
					type: {
						alias: "月份",
					},
					sales: {
						alias: "耗电量",
					},
				},
			});
			columnPlot.render();
		},
		chart_init_4() {
			const liquidPlot = new Liquid("chart_4", {
				percent: 0.5,
				outline: {
					border: 2,
					distance: 0,
				},
				statistic: {
					title: {
						formatter: () => "CO2",
						style: ({percent}) => ({
							fontSize: "1rem",
							fill: percent >= 0.65 ? "white" : "",
						}),
					},
					content: {
						style: ({percent}) => ({
							fontWeight: 100,
							fontSize: "1.5rem",
							fill: percent >= 0.5 ? "white" : "",
						}),
						customHtml: (container, view, {percent}) => {
							const text = `${(percent * 1000).toFixed(0)}`;
							return `<div>${text}</div>`;
						},
					},
				},
				wave: {
					length: 125,
				},
			});
			liquidPlot.render();
		},
		chart_init_5() {
			const data = [
				{
					type: "2022-01",
					sales: 38,
				},
				{
					type: "2022-02",
					sales: 52,
				},
				{
					type: "2022-03",
					sales: 61,
				},
				{
					type: "2022-04",
					sales: 145,
				},
				{
					type: "2022-05",
					sales: 48,
				},
				{
					type: "2022-06",
					sales: 38,
				},
				{
					type: "2022-07",
					sales: 38,
				},
				{
					type: "2022-08",
					sales: 38,
				},
			];

			const columnPlot = new Column("chart_5", {
				data,
				xField: "type",
				yField: "sales",
				color: "#7367f0",
				label: {
					position: "top", // 'top', 'bottom', 'middle',
					// 配置样式
					style: {
						fill: "#FFFFFF",
						opacity: 0.6,
					},
				},
				xAxis: {
					label: {
						autoHide: true,
						autoRotate: false,
					},
				},
				yAxis: {
					grid: null,
				},
				meta: {
					type: {
						alias: "月份",
					},
					sales: {
						alias: "耗电量",
					},
				},
			});
			columnPlot.render();
		},
		chart_init_6() {
			const liquidPlot = new Liquid("chart_6", {
				percent: 0.5,
				outline: {
					border: 2,
					distance: 0,
				},
				statistic: {
					title: {
						formatter: () => "CO2",
						style: ({percent}) => ({
							fontSize: "1rem",
							fill: percent >= 0.65 ? "white" : "",
						}),
					},
					content: {
						style: ({percent}) => ({
							fontWeight: 100,
							fontSize: "1.5rem",
							fill: percent >= 0.5 ? "white" : "",
						}),
						customHtml: (container, view, {percent}) => {
							const text = `${(percent * 1000).toFixed(0)}`;
							return `<div>${text}</div>`;
						},
					},
				},
				wave: {
					length: 125,
				},
			});
			liquidPlot.render();
		},
		chart_init_7() {
			const data = [
				{time: "00:00", series: "室内", temperature: 7},
				{time: "00:00", series: "室外", temperature: 3.9},
				{time: "01:00", series: "室内", temperature: 6.9},
				{time: "01:00", series: "室外", temperature: 4.2},
				{time: "02:00", series: "室内", temperature: 9.5},
				{time: "02:00", series: "室外", temperature: 5.7},
				{time: "03:00", series: "室内", temperature: 14.5},
				{time: "03:00", series: "室外", temperature: 8.5},
				{time: "04:00", series: "室内", temperature: 18.4},
				{time: "04:00", series: "室外", temperature: 11.9},
				{time: "05:00", series: "室内", temperature: 21.5},
				{time: "05:00", series: "室外", temperature: 15.2},
				{time: "06:00", series: "室内", temperature: 25.2},
				{time: "06:00", series: "室外", temperature: 17},
				{time: "07:00", series: "室内", temperature: 26.5},
				{time: "07:00", series: "室外", temperature: 16.6},
				{time: "08:00", series: "室内", temperature: 23.3},
				{time: "08:00", series: "室外", temperature: 14.2},
				{time: "09:00", series: "室内", temperature: 18.3},
				{time: "09:00", series: "室外", temperature: 10.3},
				{time: "10:00", series: "室内", temperature: 13.9},
				{time: "10:00", series: "室外", temperature: 6.6},
				{time: "11:00", series: "室内", temperature: 9.6},
				{time: "11:00", series: "室外", temperature: 4.8},
				{time: "12:00", series: "室内", temperature: 9.5},
				{time: "12:00", series: "室外", temperature: 5.7},
				{time: "13:00", series: "室内", temperature: 14.5},
				{time: "13:00", series: "室外", temperature: 8.5},
				{time: "14:00", series: "室内", temperature: 18.4},
				{time: "14:00", series: "室外", temperature: 11.9},
				{time: "15:00", series: "室内", temperature: 21.5},
				{time: "15:00", series: "室外", temperature: 15.2},
				{time: "16:00", series: "室内", temperature: 25.2},
				{time: "16:00", series: "室外", temperature: 17},
				{time: "17:00", series: "室内", temperature: 26.5},
				{time: "17:00", series: "室外", temperature: 16.6},
				{time: "18:00", series: "室内", temperature: 23.3},
				{time: "18:00", series: "室外", temperature: 14.2},
				{time: "19:00", series: "室内", temperature: 18.3},
				{time: "19:00", series: "室外", temperature: 10.3},
				{time: "20:00", series: "室内", temperature: 13.9},
				{time: "20:00", series: "室外", temperature: 6.6},
				{time: "21:00", series: "室内", temperature: 9.6},
				{time: "21:00", series: "室外", temperature: 4.8},
				{time: "22:00", series: "室内", temperature: 9.5},
				{time: "22:00", series: "室外", temperature: 5.7},
				{time: "23:00", series: "室内", temperature: 14.5},
				{time: "23:00", series: "室外", temperature: 8.5},
			];

			const piePlot = new Line("chart_7", {
				data,
				xField: "time",
				yField: "temperature",
				seriesField: "series",
				yAxis: {
					nice: true,
					grid: {
						line: {
							style: {
								stroke: "#82868b",
								lineWidth: 1,
								lineDash: [1, 6],
								strokeOpacity: 0.7,
							},
						},
					},
					title: {
						text: "温度 (°C)",
						style: {
							fill: "#808695",
						},
					},
				},
				smooth: true,
				legend: {
					position: "bottom",
					itemName: {
						style: {
							fill: "#808695",
						},
					},
				},
			});

			piePlot.render();
		},
		chart_init_8() {
			const data = [
				{type: "幼儿园", value: 27},
				{type: "停车场", value: 15},
				{type: "其它商业", value: 25},
			];

			const bar = new Bar("chart_8", {
				data,
				xField: "value",
				yField: "type",
				xAxis: {
					label: null,
					grid: null,
				},
				seriesField: "type",
				legend: false,
				label: {
					position: "right",
					style: {
						fill: "#808695",
						opacity: 0.8,
					},
				},
			});

			bar.render();
		},
		chart_init_9() {
			const data = [
				{type: "幼儿园", value: 27},
				{type: "停车场", value: 15},
				{type: "其它商业", value: 25},
			];

			const bar = new Bar("chart_9", {
				data,
				xField: "value",
				yField: "type",
				xAxis: {
					label: null,
					grid: null,
				},
				seriesField: "type",
				legend: false,
				label: {
					position: "right",
					style: {
						fill: "#808695",
						opacity: 0.8,
					},
				},
			});

			bar.render();
		},
		chart_init_10() {
			const data = [
				{time: "00:00", series: "室内", temperature: 7},
				{time: "00:00", series: "室外", temperature: 3.9},
				{time: "01:00", series: "室内", temperature: 6.9},
				{time: "01:00", series: "室外", temperature: 4.2},
				{time: "02:00", series: "室内", temperature: 9.5},
				{time: "02:00", series: "室外", temperature: 5.7},
				{time: "03:00", series: "室内", temperature: 14.5},
				{time: "03:00", series: "室外", temperature: 8.5},
				{time: "04:00", series: "室内", temperature: 18.4},
				{time: "04:00", series: "室外", temperature: 11.9},
				{time: "05:00", series: "室内", temperature: 21.5},
				{time: "05:00", series: "室外", temperature: 15.2},
				{time: "06:00", series: "室内", temperature: 25.2},
				{time: "06:00", series: "室外", temperature: 17},
				{time: "07:00", series: "室内", temperature: 26.5},
				{time: "07:00", series: "室外", temperature: 16.6},
				{time: "08:00", series: "室内", temperature: 23.3},
				{time: "08:00", series: "室外", temperature: 14.2},
				{time: "09:00", series: "室内", temperature: 18.3},
				{time: "09:00", series: "室外", temperature: 10.3},
				{time: "10:00", series: "室内", temperature: 13.9},
				{time: "10:00", series: "室外", temperature: 6.6},
				{time: "11:00", series: "室内", temperature: 9.6},
				{time: "11:00", series: "室外", temperature: 4.8},
				{time: "12:00", series: "室内", temperature: 9.5},
				{time: "12:00", series: "室外", temperature: 5.7},
				{time: "13:00", series: "室内", temperature: 14.5},
				{time: "13:00", series: "室外", temperature: 8.5},
				{time: "14:00", series: "室内", temperature: 18.4},
				{time: "14:00", series: "室外", temperature: 11.9},
				{time: "15:00", series: "室内", temperature: 21.5},
				{time: "15:00", series: "室外", temperature: 15.2},
				{time: "16:00", series: "室内", temperature: 25.2},
				{time: "16:00", series: "室外", temperature: 17},
				{time: "17:00", series: "室内", temperature: 26.5},
				{time: "17:00", series: "室外", temperature: 16.6},
				{time: "18:00", series: "室内", temperature: 23.3},
				{time: "18:00", series: "室外", temperature: 14.2},
				{time: "19:00", series: "室内", temperature: 18.3},
				{time: "19:00", series: "室外", temperature: 10.3},
				{time: "20:00", series: "室内", temperature: 13.9},
				{time: "20:00", series: "室外", temperature: 6.6},
				{time: "21:00", series: "室内", temperature: 9.6},
				{time: "21:00", series: "室外", temperature: 4.8},
				{time: "22:00", series: "室内", temperature: 9.5},
				{time: "22:00", series: "室外", temperature: 5.7},
				{time: "23:00", series: "室内", temperature: 14.5},
				{time: "23:00", series: "室外", temperature: 8.5},
			];

			const piePlot = new Line("chart_10", {
				data,
				xField: "time",
				yField: "temperature",
				seriesField: "series",
				yAxis: {
					nice: true,
					grid: {
						line: {
							style: {
								stroke: "#82868b",
								lineWidth: 1,
								lineDash: [1, 6],
								strokeOpacity: 0.7,
							},
						},
					},
					title: {
						text: "温度 (°C)",
						style: {
							fill: "#808695",
						},
					},
				},
				smooth: true,
				legend: {
					position: "bottom",
					itemName: {
						style: {
							fill: "#808695",
						},
					},
				},
			});

			piePlot.render();
		},
		chart_init_11() {
			const data = [
				{type: "D座高区", value: 37},
				{type: "D座低区", value: 52},
				{type: "D座其它", value: 18},
			];

			const piePlot = new Pie("chart_11", {
				appendPadding: 10,
				data,
				angleField: "value",
				colorField: "type",
				radius: 1,
				innerRadius: 0.6,
				pieStyle: {
					lineWidth: 0,
				},
				label: {
					type: "inner",
					offset: "-50%",
					content: "{value}",
					style: {
						textAlign: "center",
						fontSize: 14,
					},
				},
				interactions: [
					{type: "element-selected"},
					{type: "element-active"},
				],
				statistic: {
					title: {
						formatter: () => "合计",
						offsetY: -8,
						style: {
							fontSize: "0.9rem",
							color: "",
						},
					},
					content: {
						offsetY: -4,
						style: {
							fontSize: "1.5rem",
							fontWeight: "normal",
							color: "",
						},
					},
				},
				legend: {
					itemName: {
						style: {
							fill: "#808695",
						},
					},
				},
			});

			piePlot.render();
		},
		chart_init_12() {
			const liquidPlot = new Liquid("chart_12", {
				percent: 0.5,
				outline: {
					border: 2,
					distance: 0,
				},
				statistic: {
					title: {
						formatter: () => "CO2",
						style: ({percent}) => ({
							fontSize: "1rem",
							fill: percent >= 0.65 ? "white" : "",
						}),
					},
					content: {
						style: ({percent}) => ({
							fontWeight: 100,
							fontSize: "1.5rem",
							fill: percent >= 0.5 ? "white" : "",
						}),
						customHtml: (container, view, {percent}) => {
							const text = `${(percent * 1000).toFixed(0)}`;
							return `<div>${text}</div>`;
						},
					},
				},
				wave: {
					length: 125,
				},
			});
			liquidPlot.render();
		},
	},
	mounted() {
		this.chart_init_1();
		this.chart_init_2();
		this.chart_init_3();
		this.chart_init_4();
		this.chart_init_5();
		this.chart_init_6();
		this.chart_init_7();
		this.chart_init_8();
		this.chart_init_9();
		this.chart_init_10();
		this.chart_init_11();
		this.chart_init_12();
	},
};
</script>

<style scoped>
</style>